<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Safety Agreement Form</h2>

      <p class="mb-n3">
        a) i) Do you wear contact lenses? ii) If you do, do you realize that you have different
        goggle requirements from students who do NOT wear contact lenses and what those requirements
        are?
      </p>

      <v-radio-group v-model="inputs.contactLenses" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsYN1"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">b) Are you color blind?</p>

      <v-radio-group v-model="inputs.colorBlind" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsYN"
          :key="'pt-2-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        c) Do you have any allergies or medical conditions that we need to know about? If the answer
        is yes, please answer yes to this question and proceed to part d) and list them. If you
        answer no, please proceed to part e) of this form.
      </p>

      <v-radio-group v-model="inputs.medicalConditions" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsYN"
          :key="'pt-3-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">
        d) Please tell us about any medical condition(s) and/or allergy(ies) that you think may be
        relevant to us taking good care of you during the upcoming semester. This could be an
        allergy to latex, diabetes, seizures, pregnancy, or anything else you may be concerned
        about. You can also list the phone number of a family member or best friend to call in case
        of an emergency in the lab.
      </p>

      <s-textarea v-model="inputs.medicalConditionsDetails" outlined></s-textarea>

      <p class="mb-n3">
        e) I have read and understood the syllabus, safety rules, and lab notebook guidelines (all
        can be found on Blackboard), and I have completed the three (or four) questions above
        regarding allergies, medical conditions, color blindness, and wearing contacts.
      </p>

      <v-radio-group
        v-model="inputs.safetyFormAgreement"
        class="ml-6 mb-0"
        :disabled="!allowEditing"
      >
        <v-radio
          v-for="option in optionsTF"
          :key="'pt-5-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea';

export default {
  name: 'OleMissSafetyAgreementForm',
  components: {StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        contactLenses: null,
        colorBlind: null,
        medicalConditions: null,
        medicalConditionsDetails: null,
        safetyFormAgreement: null,
      },
      optionsYN1: [
        {text: '$\\text{i) No}$', value: 'no'},
        {text: '$\\text{i) Yes, ii) No}$', value: 'yesNo'},
        {text: '$\\text{i) Yes, ii) Yes}$', value: 'yesYes'},
      ],
      optionsYN: [
        {text: '$\\text{Yes}$', value: 'yes'},
        {text: '$\\text{No}$', value: 'no'},
      ],
      optionsTF: [
        {text: '$\\text{True}$', value: 'true'},
        {text: '$\\text{False}$', value: 'false'},
      ],
    };
  },
};
</script>
<style scoped></style>
